import React from "react"
import styled from "styled-components"

import handbookEditorImageJPG from "./handbook_editor.jpg"
import handbookEditorImageWEBP from "./handbook_editor.webp"
import handbookEditorImage600x412JPG from "./handbook_editor_600x412.jpg"
import handbookEditorImage600x412WEBP from "./handbook_editor_600x412.webp"

import { Row, Col, Img } from "./style"
import { ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"
import { Title, SubTitle } from "src/componentsV2/sections/Text"

const CreativeServicesContainer = styled(ContainerPage)`
  padding-top: 45px;
  @media (min-width: 768px) {
    padding-top: 45px;
  }
`

const index = () => {
  return (
    <CreativeServicesContainer isBackgroundWhite data-loadable-component="creative-services">
      <WrapperPage>
        <Row className="items-center">
          <Col className="description">
            <Title className="title">
              White-Glove Employee Handbook Building Services
            </Title>
            <SubTitle className="px-4 md:px-0">
              Send AirMason your existing handbook content and our experts will take it from there.
              From policy drafting and copywriting to brand-aligned design, we’re here to help you achieve your employee handbook vision
            </SubTitle>
          </Col>
          <Col className="text-right">
            <picture>
              <source srcSet={handbookEditorImageWEBP} type="image/webp" media="(min-width: 769px)" />
              <source srcSet={handbookEditorImageJPG} type="image/jpg" media="(min-width: 769px)" />
              <source srcSet={handbookEditorImage600x412WEBP} type="image/webp" />
              <source srcSet={handbookEditorImage600x412JPG} type="image/jpg" />
              <Img src={handbookEditorImageJPG} width="600" height="412" alt='handbook editor' />
            </picture>
          </Col>
        </Row>
      </WrapperPage>
    </CreativeServicesContainer>
  )
}

export default index
